body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// Global SCSS for anchor link

.anchor {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 2px;
  cursor: pointer;
  line-height: 2.7;
  margin: 1em 0;

  &::after {
    display: inline-block;
    padding-left: 8px;
    content: "\0279E"; // arrow right unicode
    -webkit-transition: transform 0.3s ease-out;
    -moz-transition: transform 0.3s ease-out;
    -ms-transition: transform 0.3s ease-out;
    -o-transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
  }

  &:hover {
    // color: #0c5449;
    // background-color: #f6f3ed;

    &::after {
      -webkit-transform: translateX(4px);
      -moz-transform: translateX(4px);
      -ms-transform: translateX(4px);
      -o-transform: translateX(4px);
      transform: translateX(4px);
    }
  }
}

/* Handling Menu PopOver Radius Forcely === Globally */

.MuiPopover-paper {
  // max-width: 300px !important;
  // width: 280px !important;
  margin-top: 40px;
  border-radius: 20px;
}

/* Handling Dialog Radius Forcely */

.MuiDialog-paper {
  border-radius: 20px !important;
  min-width: 300px;
  min-height: 300px;
}

// Not Joined 

.is__not__joined {
  font-size: 40px;
  text-align: center;
  font-weight: bold;
    span {
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
}

// Tab Styles 

.tab {
  &__indicator {
    height: 3px;
    // background-color: #51513F;
  }
  &__root {
    text-transform: capitalize;
    font-size: 14px;
    font-weight: normal;
    color: #fff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    letter-spacing: 1px;
    border-bottom: 1px solid rgba(171, 184, 202, 0.25);
  }
  &__selected {
    // color: #0D809F !important;
    color: #fff !important;
    // background: #E7F8FD;
    background: #070709;
  }
  &__extraBorder {
    min-width: 300px;
    border-bottom: 1px solid rgba(171, 184, 202, 0.25);
    @media (max-width: 900px) {
      min-width: 0px !important;
    }
  }
}

/* Scroll  */
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(96.12deg, #2193b0 0%, #6dd5ed 156.51%);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(96.12deg, #2193b0 0%, #6dd5ed 156.51%);
}