.remote__player {
  width: 100%;
  height: 90vh;

  div {
    border-radius: 16px;
  }
}

.local__player {
  // width: 300px;
  // height: 200px;
  // position: absolute;
  // right: 50px;
  // bottom: 50px;
  // z-index: 1;

  /* Based on new design */

  width: 200px;
  height: 150px;
  position: absolute;
  right: 10px;
  top: 50px;
  z-index: 1;
  div {
    border-radius: 16px;
  }
}