.main__chatcontent {
  flex-grow: 1;
  padding: 10px 5px;
  /* max-width: 63%; */
  border-right: 1px solid #ebe7fb;
}
.content__header {
  padding-bottom: 15px;
  border-bottom: 1px solid #ebe7fb;
}
.current-chatting-user {
  display: flex;
  align-items: center;
}
.current-chatting-user p {
  margin: 0;
  font-weight: 600;
}
.content__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.settings .btn-nobg {
  color: #000;
}
.content__body {
  max-height: calc(100vh - calc(100vh / 2.9));
  overflow: auto;
  height: calc(100vh - calc(100vh / 3.7));

  @media (max-width: 900px) {
    max-height: calc(100vh - calc(100vh / 3.5));
  }
}
.chat__item {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 15px;
  transition: all 0.3s ease;
  transform: scale(0);
  transform-origin: right;
  animation-name: showIn;
  animation-duration: 0.2s; /* or: Xms */
  animation-iteration-count: 1;
  animation-direction: normal; /* or: normal */
  animation-timing-function: cubic-bezier(
    0.88,
    0.19,
    0.37,
    1.11
  ); /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
  animation-fill-mode: both; /* or: backwards, both, none */
  animation-delay: 0.2s; /* or: Xms */
  position: relative;
  &.me {
    .chat__item__content {
      background-color: #0d809f;
      color: #fff;
      padding: 15px;
      // border-radius: 10px 10px 0 10px;
      border-radius: 4px;
      max-width: 50%;
      min-width: 215px;
      margin-right: 6%;
      @media(max-width: 1300px) {
        margin-right: 8%;
      }
      @media(max-width: 1000px) {
        margin-right: 10%;
      }
      @media(max-width: 900px) {
        margin-right: 6%;
      }
      @media(max-width: 600px) {
        margin-right: 10%;
      }
    }
    .avatar {
      margin-right: 0px;
      margin-left: 20px;
      background: #fff;
      padding: 1px;
      position: absolute;
      top: 0;
    }
  }

  &.other {
    flex-direction: row-reverse;
    transform-origin: left;
    .chat__item__content {
      background-color: #E7F8FD;
      color: #111;
      padding: 15px;
      // border-radius: 10px 10px 0 10px;
      border-radius: 4px;
      max-width: 50%;
      min-width: 215px;
      margin-left: 6%;
      @media(max-width: 1300px) {
        margin-left: 8%;
      }
      @media(max-width: 1000px) {
        margin-left: 10%;
      }
      @media(max-width: 900px) {
        margin-left: 6%;
      }
      @media(max-width: 600px) {
        margin-left: 10%;
      }
      .chat__meta {
        span {
          color: #111;
        }
      }
      .chat__meta__name {
        span {
          color: #111;
        }
      }
    }
    .avatar {
      margin-right: 20px;
      margin-left: 0px;
      background: #fff;
      padding: 1px;
      position: absolute;
      top: 0;
    }
  }

  .chat__meta {
    justify-content: space-between;
    display: flex;
    margin-top: 5px;
    span {
      font-size: 10px;
      color: #f0f0f0;
      user-select: none;
      font-style: italic;
    }
  }
  .chat__meta__name {
    justify-content: space-between;
    display: flex;
    margin-bottom: 10px;
    span {
      font-size: 10px;
      color: #f0f0f0;
      user-select: none;
      font-style: italic;
      font-weight: bold;
      text-transform: capitalize;
    }
  }

  .chat__msg {
    user-select: none;
    word-wrap: break-word;
  }
}


@keyframes showIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.content__footer {
  padding-top: 10px;
}
.sendNewMessage {
  background-color: #f1f3f4;
  border-radius: 25px;
  padding: 12px 0px;

  form {
    display: flex;
    align-items: center;
  }
}

.sendNewMessage button {
  // width: 36px;
  // height: 36px;
  min-width: 0px !important;
  margin-right: 12px;
  background-color: #f1f3f4;
  border: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  color: #b1b4c2;
  padding: 0;
  border-radius: 5px;
  line-height: 36px;
  transition: all 0.3s cubic-bezier(0.88, 0.19, 0.37, 1.11);
}
.sendNewMessage button:hover {
  // transform: scale(1.1);
  background: #f1f3f4;
  box-shadow: none;
}

.send__icon {
  display: block !important;
  fill: #fff !important;
  transform: rotateZ(-45deg);
  margin-left: 8px;
}
.sendNewMessage input {
  flex-grow: 1;
  padding: 0 15px;
  background-color: transparent;
  border: none;
  outline: none;
  color: #202124;
  font-size: 14px;
}
#sendMsgBtn {
  background-color: dodgerblue;
  color: #fff;
}

.avatar {
  width: 30px;
  height: 30px;
  text-transform: capitalize;
  font-weight: bold;
}
.avatar img {
  max-width: 100%;
  object-fit: cover;
}

.isOnline {
  position: absolute;
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #ddd;
  border-radius: 50%;
  border: 2px solid #fff;
}
.isOnline.active {
  background-color: lightgreen;
}
.avatar__img {
  overflow: hidden;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.loading__button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.file__name {
  width: 120px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 0px 10px;
}
