.rt {
  padding-top: 16px;
  width: 100%;
  &__box {
    &__inner {
      display: flex;
      align-items: center;
      &__title {
        font-size: 18px;
        font-weight: 500;
        padding-left: 8px;
      }
    }
  }
}


// Separated beacuse we need this in future

// Glucose View

.glu {
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 16px;
  }
}

// Blood Pressure View

.bp {
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 16px;
  }
}

// Body Weight View

.wt {
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 16px;
  }
}

// Oxygen Saturation View

.os {
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 16px;
  }
}

// Pulse View

.pl {
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 16px;
  }
}

// Temperature View

.tp {
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 16px;
  }
}


// Pathalogy Reports

.prt {
  &__inner {
    // width: 172px;
    // height: 130px;
    border: 1px solid rgba(0,0,0,0.04);
    border-radius: 13px;
    padding: 16px;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
    margin: 12px;
    &__title {
      font-size: 16px;
      padding-top: 8px;
      font-weight: 500;
      text-transform: capitalize;
    }
    &__summary {
      font-size: 14px
    }
    &__date {
      font-size: 12px;
      font-weight: bold;
      color: gray;
      letter-spacing: 1px;
      padding-top: 8px;
    }
  }
}

@media (max-width: 600px) {
  .prt {
    &__inner {
      // width: 120px;
    }
  }
}