// Message Drawer

.msg {
  width: 0px;
  flex-shrink: 0;
  position: absolute;
  z-index: 1;

  &__paper {
    /* For Google meet popup */

    // width: 360px;
    // background: #fff;
    // height: calc(100% - 133px);
    // top: 64px;
    // right: 10px;
    // border-radius: 8px;

    width: 47%;
    background: #fff;
    height: 90vh;
    top: 68px;
    right: 24px;
    border-radius: 8px;
  }
  &__inner {
    padding: 16px;
    padding-bottom: 0px;
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &__title {
        font-size: 1.245rem;
        line-height: 1.5rem;
      }
      &__button {
        text-transform: capitalize;
        font-weight: normal;
        font-size: 16px;
        background: #e7f8fd;
        color: #0d809f;
        border: 1px solid #0d809f;
        border-radius: 100px;
        box-shadow: none;
      }
    }
    &__info {
      font-size: 0.75rem;
      text-align: center;
      padding: 12px;
      background: #f1f3f4;
      border-radius: 8px;
      margin-top: 12px;
    }
  }
}

// Report Drawer

// Message Drawer

.report {
  width: 0px;
  flex-shrink: 0;
  position: absolute;
  z-index: 1;
  &__paper {
    /* For Google meet popup */

    // width: 360px;
    // background: #fff;
    // height: calc(100% - 133px);
    // top: 64px;
    // right: 10px;
    // border-radius: 8px;

    width: 47%;
    background: #fff;
    height: 90vh;
    top: 68px;
    right: 24px;
    border-radius: 8px;
  }
  &__inner {
    padding: 16px;
    padding-bottom: 0px;
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &__title {
        font-size: 1.245rem;
        line-height: 1.5rem;
      }
      &__button {
        text-transform: capitalize;
        font-weight: normal;
        // font-size: 16px;
        background: #e7f8fd;
        color: #0d809f;
        border: 1px solid #0d809f;
        border-radius: 100px;
        box-shadow: none;
        transition: 0.6s all ease-in-out;
        &:hover {
          color: #fff;
          transform: scale3d(1.1, 1.1, 1.1);
        }
      }
    }
    &__info {
      font-size: 0.75rem;
      text-align: center;
      padding: 12px;
      background: #f1f3f4;
      border-radius: 8px;
      margin-top: 12px;
    }
    &__scroller {
      max-height: 75vh;
      height: 80vh;
      overflow: hidden;
      overflow-y: scroll;

      display: flex;
      flex-wrap: wrap;

      // padding-top: 16px;
    }
  }
}



// Swipeable Drawer

.swipe {
  .swipe__inner {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: #111;
    padding: 16px;
    height: 100%;
    overflow: auto;
    .iconButton {
      color: #fff;
      min-width: 33%;
      min-height: 50%;
      svg {
        width: 30px;
        height: 30px;
      }
      p {
        font-size: 14px;
        padding-top: 8px;
      }
    }
  }
}

// Responsive View 

@media (max-width: 900px) {
  .msg {
    &__paper {
      height: 100% !important;
      width: 100% !important;
      top: 0;
      right: 0;
      border-radius: 0px;
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }
  .report {
    &__paper {
      height: 100% !important;
      width: 100% !important;
      top: 0;
      right: 0;
      border-radius: 0px;
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }
}