.top__root {
  border-bottom-left-radius: 20px;
  max-width: fit-content;
  background: #282C34;
  .title {
    font-weight: 500;
  }
  span {
    padding: 0px 10px;
  }
  .timer {
    font-weight: 500;
  }
}

/* Bottom Bar */

.bottom__root {
  position: absolute;
  bottom: 0;
  top: auto;
  background: transparent !important;
  min-height: 50px !important;
  .left {
    // flex-grow: 1;
    width: 20%;
    max-width: 20%;
  }
  .middle {
    // flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    max-width: 60%;
    .ava__icon {
      // box-shadow: 5px 5px 40px #101215, -5px -5px 0px #282c34;
      background-color: #282C34;
      width:40px;
      height:40px;
    }
  }
  .right {
    width: 20%;
    max-width: 20%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0px 2%;
    .ava__icon {
      // box-shadow: 5px 5px 40px #101215, -5px -5px 0px #282c34;
      background-color: #282C34;
      width: 40px;
      height:40px;
    }
  }

}

// Hide Bottom when any drawer open in mobile view

.bottom__hide {
  display: none;

}

.global__drawer__mobile {
  position: absolute;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -20%);
  z-index: 9;
  .ava__icon {
    background-color: #111;
    width: 30px;
    height: 30px
  }
}

@media(max-width: 600px) {
  
}